<template>
  <div class="modal" v-if="calcModal">
    <div class="modal__back" @click="$emit('switchModal')"></div>
    <div class="modal__body trade__modal--clamp">
      <form class="trade__modal" @submit.prevent="createContract" autocomplete="off">
        <div class="trade__modal__header">
          <h1>Создание договора лизинга</h1>
          <button class="btn btn--gray" @click="$emit('switchModal')">Закрыть</button>
        </div>
        <div class="trade__modal__body">
          <div class="ct__row" v-if="calc.insurance">
            <div class="ct__col">
              <div class="ct__input">
                <label>Страховая компания:</label>
                <v-select
                  label="name"
                  v-model="insurance"
                  :options="calc.insurance"
                  :reduce="item => item.id"
                  placeholder="Выберите страховую компанию"
                />
              </div>
            </div>
          </div>
          <div class="ct__row" style="font-size:1.2rem; color:red;" v-else>Не заполнена финансовая организация</div>
          <div class="ct__row">
            <div class="ct__col">
              <div class="ct__input">
                <label>Тип договора страхования:</label>
                <v-select
                  v-model="contractType"
                  :options="contractTypes"
                  placeholder="Выберите тип договора"
                />
              </div>
            </div>
          </div>
          <div class="ct__row">
            <div class="ct__col">
              <div class="ct__input">
                <label>Плательщик:</label>
                <v-select
                  v-model="payer"
                  :options="payerTypes"
                  placeholder="Выберите плательщика"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="trade__modal__footer">
          <button type="submit" class="btn" :disabled="!calc.insurance">Создать договор</button>
          <button class="modal__attention__btn" @click="$emit('switchModal')">Закрыть</button>
        </div>
      </form>
    </div>
    <!-- <div class="modal__body" style="max-width: 50%;">
      <div class="modal__attention" style="white-space: pre-wrap;">
        <span class="modal__attention__title">Создание договора лизинга</span>
        <span class="modal__attention__subtitle" style="color:darkgrey;width:70%;word-wrap:anywhere;">{{leadId}}</span>
        <span class="modal__attention__subtitle" style="color:darkgrey;">{{calc.insurance}}</span>
        <div class="modal__attention__btns" style="justify-content:center;">
          <button class="modal__attention__btn modal__attention__btn--orange" @click="$emit('switchModal')">Создать договор</button>
          <button class="modal__attention__btn" @click="$emit('switchModal')">Закрыть</button>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import CREATE_CONTRACT from '@/graphql/mutations/CreateContract.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'CreateContractModal',
  props: [ 'calcModal', 'leadId', 'calc' ],
  data () {
    return {
      contractType: '',
      contractTypes: ['Имущество', 'КАСКО', 'ОСАГО', 'Оборудование', 'Спецтехника'],
      payer: '',
      payerTypes: ['Лизингополучатель', 'Лизингодатель'],
      insurance: ''
    }
  },
  methods: {
    async createContract () {
      if (!this.payer || !this.insurance || !this.contractType) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Не заполнены поля формы'
        })
        return
      }
      await this.$apollo
        .mutate({
          mutation: CREATE_CONTRACT,
          variables: {
            calcId: this.calc.id,
            leadId: this.leadId,
            payer: this.payer,
            insurance: this.insurance,
            contractType: this.contractType
          },
          update: (_, { data }) => {
            if (data && data.createContract) {
              this.$router.push({ name: 'contract', params: { id: data.createContract.id } })
              this.$notify({
                group: 'lsg-notify',
                text: 'Данные успешно сохранены'
              })
            }
          }
        })
        .catch((error) => {
          _graphQlHelper.graphQLErrorMessages(error).forEach((item) => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
    }
  }
}
</script>

<style scoped>
  .modal {
    align-items: center;
  }
  .modal__attention__btn {
    margin: 0px 5px;
  }
  button:disabled,
  button[disabled]{
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    pointer-events: none;
  }
</style>

<template :key="rerenderKey">
  <ApolloQuery
    :query="require('@/graphql/queries/Lead.gql')"
    :variables="{ rerenderKey: rerenderKey, id: $route.params.id, limit: $store.state.me.settings.lazy_load ? +this.limit: 0, skip: 0 }"
    @result="doneLoadingLead"
    class="lsg-page lsg-trade__page trade_page"
  >
    <template slot-scope="{ result: { loading, error, data } }">
      <div class="ct__row" v-if="error">
        <div class="ct__col">
          <div class="ct__input">
            <label for="message">Ошибка:</label>
            <input type="text" :value="error.message" id="message" disabled />
          </div>
        </div>
      </div>
      <div class="loading apollo" v-else-if="loading">Загрузка</div>
      <div class="lsg-trade" v-else-if="data && data.Lead">
        <div class="modal" style="align-items: center;" v-if="actModal">
          <div class="modal__back" @click="actModal = false"></div>
          <div class="modal__body" style="max-width: 50%;">
            <div class="modal__attention" style="white-space: pre-wrap;">
              <span class="modal__attention__title">Вы действительно хотите создать акт передачи?</span>
              <div class="modal__attention__btns" style="justify-content:center;">
                <button class="modal__attention__btn modal__attention__btn--orange" @click="createAct(checkedContract.id, checkedContract.contract_id)">Создать</button>
                <button class="modal__attention__btn" style="margin: 0px 5px;" @click="actModal = false">Закрыть</button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal" style="align-items: center;" v-if="addGuarantorModal">
          <div class="modal__back" @click="addGuarantorModal = false"></div>
          <div class="modal__body" style="max-width: 50%;">
            <div class="modal__attention" style="white-space: pre-wrap;">
              <span class="modal__attention__title">Введите ИНН поручителя:</span>
              <div class="modal__attention__subtitle">
                <input type="text" v-model="guarantor.inn">
              </div>
              <div class="modal__attention__btns" style="justify-content:center;">
                <button v-if="disableGuarantorButton" class="modal__attention__btn btn--disable">Добавить</button>
                <button v-else class="modal__attention__btn modal__attention__btn--orange" @click="addGuarantor">Добавить</button>
                <button class="modal__attention__btn" style="margin: 0px 5px;" @click="addGuarantorModal = false">Закрыть</button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal" style="align-items: center;" v-if="serviceModal">
          <div class="modal__back" @click="serviceNoteModal = false"></div>
          <div class="modal__body" style="max-width: 500px;">
            <div class="modal__attention" style="white-space: pre-wrap;">
              <span class="modal__attention__title">Выберите источник:</span>
              <div class="modal__attention__subtitle modal__attention__origin">
                <label
                  class="modal__origin__item"
                  v-for="(serviceOrigin, index) in serviceOriginEnum"
                  :key="index"
                  :for="serviceOrigin.field + index"
                >
                  <input type="radio" :id="serviceOrigin.field + index" v-model="service.origin" class="modal__origin__checkbox" :value="serviceOrigin.field" name="service">
                  <div class="modal__origin__label">{{ serviceOrigin.label }}</div>
                </label>
              </div>
              <span class="modal__attention__title">Введите комментарий для служб:</span>
              <div class="modal__attention__subtitle modal__attention__textarea">
                <textarea v-model="service.comment" minlength="10" required placeholder="Введите не менее 10 символов"></textarea>
              </div>
              <span class="modal__attention__title">Выберите продукт:</span>
              <div class="modal__origin__product">
                <v-select
                  v-model="selectProduct"
                  :options="products"
                  placeholder="Продукт"
                ></v-select>
              </div>
              <div class="modal__attention__btns" style="justify-content:center;">
                <button v-if="!isValidForCreate" class="modal__attention__btn btn--disable">Создать</button>
                <button v-else class="modal__attention__btn modal__attention__btn--orange" @click="createProject(data.Lead._id)">Создать</button>
                <button class="modal__attention__btn" style="margin: 0px 5px;" @click="serviceModal = false">Закрыть</button>
              </div>
            </div>
          </div>
        </div>
        <ContractFilesModal
          v-if="data.Lead.contracts && data.Lead.contracts.length > 0 && data.Lead.status === 'dl' && checkedContract && checkedContract.contract_id"
          :contractId = "checkedContract.contract_id"
          :contractName = "checkedContract.name"
          :filesModal="filesModal"
          :isDownload="isDownload"
          :apolloData = "apolloData"
          :signers = "checkedContract.signers[0]"
          :mongoId = "checkedContract.id"
          v-on:switchModal="filesModal = !filesModal; isDownload = false"
        />
        <ContractBuyoutModal
          v-if="data.Lead.contracts && data.Lead.contracts.length > 0 && data.Lead.status === 'dl' && checkedContract && checkedContract.contract_id"
          :contractId = "checkedContract.contract_id"
          :contractName = "checkedContract.name"
          :buyoutModal="buyoutModal"
          v-on:switchModal="buyoutModal = !buyoutModal"
        />
        <CreateContractModal
          :leadId = "data.Lead._id"
          :calc = "checkedCalc"
          :calcModal="calcModal"
          v-on:switchModal="calcModal = !calcModal"
        />
        <div class="main__header" v-if="!comments_dropdown">
          <div class="main__header__item left" style="display: flex; flex-wrap: wrap">
            <h1 v-if="data.Lead.name">{{ data.Lead.name }}</h1>
            <h1 v-else>
              {{
                data.Lead.company && data.Lead.company.name
                  ? data.Lead.company.name
                  : title
              }}
            </h1>
            <div style="display: inline-block">
              <div
                v-if="data.Lead.status === 'created'"
                class="lead-rating"
                :class="{
                  'lead-rating--green': data.Lead.company.rating && data.Lead.company.rating.includes('good'),
                  'lead-rating--yellow': data.Lead.company.rating && data.Lead.company.rating.includes('average'),
                  'lead-rating--red': data.Lead.company.rating && (data.Lead.company.rating.includes('bad') || data.Lead.company.rating.includes('negative')),
                }"
              >
                <ManIcon/>
              </div>
              <div
                v-if="data.Lead.status === 'created'"
                class="lead-accreditation lead-accreditation--yellow"
              >
                <CarIcon/>
              </div>
              <!-- <div
                class="lead-accreditation"
              >
                <TruckIcon/>
              </div>
              <div
                class="lead-accreditation"
              >
                <TractorIcon/>
              </div>
              <div
                class="lead-accreditation"
              >
                <FactoryIcon/>
              </div>
              <div
                class="lead-accreditation"
              >
                <HouseIcon/>
              </div> -->
              <div
                v-if="data.Lead.status === 'created'"
                class="lead-accreditation"
                :class="{
                  'lead-accreditation--green': data.Lead.company.accreditation && data.Lead.company.accreditation.includes('good'),
                  'lead-accreditation--yellow': data.Lead.company.accreditation && data.Lead.company.accreditation.includes('average'),
                  'lead-accreditation--red': data.Lead.company.accreditation && data.Lead.company.accreditation.includes('bad')
                }"
              >
                <BuildingFactoryIcon/>
              </div>
            </div>
          </div>
          <div class="main__header__item"></div>
          <div style="display: flex; flex-wrap: wrap; flex-direction: column" class="main__header__item right">
            <div style="display: flex; flex-wrap: wrap; justify-content: flex-end">
              <label v-if="data.Lead.project_id" class="item__switch">
                <SwitcherAlt :value="dataList.tasks" @updateTable="switchTasks" />
                <mq-layout :mq="[ 'lg', 'xlg', 'xl' ]">
                  <span>Ход рассмотрения</span>
                </mq-layout>
              </label>
              <a
                @click.prevent="showServiceModal"
                class="btn"
                style="min-width: 80px"
                href="#"
                v-if="!data.Lead.project_id && !createLoad"
                >Создать проект 1С</a
              >
              <button
                class="btn"
                style="min-width: 80px"
                v-else-if="!data.Lead.project_id && createLoad"
                disabled
                >Создать проект 1С</button
              >
              <div
                v-if="
                  data.Lead &&
                  data.Lead.project_id &&
                  ['Договор', 'Одобрен', 'Создание', 'Рассмотрение'].includes(getStatus(data.Lead.status))
                "
                class="btn"
                style="margin: 10px; min-width: 80px"
                @click="$router.push({ name: 'check_trade', params: { id: data.Lead._id } })"
              >Проверка реквизитов</div>
              <a
                @click.prevent="
                  calcModal = true
                "
                class="btn"
                style="min-width: 80px; margin: 10px 0;"
                href="#"
                v-if="data.Lead.status === 'approved' && data.Lead.calculators && data.Lead.calculators[0]"
                >+ Договор</a
              >
              <span
                class="btn btn--gray"
                style="min-width: 80px; margin: 10px 0;"
                disabled
                v-else-if="data.Lead.status === 'approved'"
                v-tooltip="'Нет имущества в проекте'"
                >+ Договор</span
              >
              <a
                @click.prevent="startProject(data.Lead._id)"
                class="btn"
                style="margin: 10px; min-width: 80px"
                href="#"
                v-if="data.Lead.project_id && getStatus(data.Lead.status) === 'Создание' && !createLoad"
                >Запуск процесса</a
              >
              <button
                class="btn"
                style="margin: 10px; min-width: 80px"
                v-if="data.Lead.project_id && getStatus(data.Lead.status) === 'Создание' && createLoad"
                disabled
                >Запуск процесса</button
              >
            </div>
          </div>
        </div>
        <div class="main__content">
          <div class="crm">
            <aside v-if="!comments_dropdown" class="crm__aside">
              <div class="workspace_left__header">
                <div class="workspace_left__row">
                  <!-- <div class="workspace_left__item">
                    <span class="workspace_left__number">ЛП</span>
                  </div> -->
                  <div
                    v-if="data.Lead.creator.name === 'Ройстат' || data.Lead.internet"
                    class="workspace_left__item"
                  >
                    <span>Интернет-заявка</span>
                  </div>
                </div>
                <div class="workspace_left__row">
                  <div class="workspace_left__item">
                    <span
                      :class="getStatusColor(data.Lead.status)"
                      class="tag"
                      >{{ getStatus(data.Lead.status) || "Нет статуса" }}</span
                    >
                  </div>
                  <div class="workspace_left__item">
                    <div class="workspace_left__types">
                      <!-- <span class="workspace__badge">ЛКТ</span>
                      <span class="workspace__badge">Л/А</span> -->
                    </div>
                    <div class="workspace_left__created">
                      <span>{{ getDate(data.Lead.createdAt) }}</span>
                    </div>
                  </div>
                </div>
                <div class="workspace_left__row">
                  <div
                    class="workspace_left__item"
                    v-if="data.Lead.tags[0] && data.Lead.tags[0].name"
                  >
                    <ul class="workspace_left__tags">
                      <li v-if="data.Lead.callCenter">Контакт-Центр</li>
                      <li :key="index" v-for="(tag, index) in data.Lead.tags">
                        {{ tag.name }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="workspace_left__row mb5">
                  <div class="workspace_left__item">
                    <span class="workspace_left__creator">{{
                      data.Lead.creator.name
                    }}</span>
                  </div>
                  <div class="workspace_left__item">
                    <span class="workspace_left__manager">{{
                      data.Lead.responsible_user.name
                    }}</span>
                  </div>
                </div>
                <div class="workspace_left__row">
                  <div class="workspace_left__item">
                    <div class="workspace_left__budget" v-if="data.Lead.calculators.length">
                      <span>Бюджет</span>
                      <strong
                        >{{
                          prettifyPrice(
                            data.Lead.calculators
                              .map((item) => (item.summ ? item.summ : 0))
                              .reduce(
                                (a, b) => parseFloat(a) + parseFloat(b),
                                0
                              )
                          )
                        }}
                        руб.</strong
                      >
                    </div>
                  </div>
                  <div class="workspace_left__item">
                    <a
                      href="#"
                      class="settings"
                      @click.prevent="showMainDataModal"
                    >
                      <NewSettingsIcon />
                    </a>
                  </div>
                </div>
                <div v-if="checkedContract && data.Lead.status === 'dl'" class="workspace_left__row">
                  <div class="workspace_left__item">
                    <div class="small-buttons">
                      <a class="btn btn--small btn--white btn--trade" @click="filesModal = true" v-tooltip:bottom="'Сформировать документы'">
                        <i>
                          <UploadNewIcon/>
                        </i>
                      </a>
                      <a class="btn btn--small btn--white btn--trade" @click="filesModal = true; isDownload = true" v-tooltip:bottom="'Загрузить документы'">
                        <i>
                          <DownloadNewIcon/>
                        </i>
                      </a>
                      <a class="btn btn--small btn--white btn--trade" @click="actModal = !actModal" v-tooltip:bottom="'Сформировать акт передачи'">
                        <i>
                          <TransferIcon/>
                        </i>
                      </a>
                    </div>
                  </div>
                </div>
                <!-- <div class="workspace_left__row">
                  <div class="workspace_left__item">
                    <div class="workspace_left__budget">
                      <div class="btn">Забрать себе</div>
                    </div>
                  </div>
                </div> -->
              </div>
              <div v-if="data.Lead.company.inn" class="workspace_left__row accordion" style="border-bottom: 1px solid #d9d9d9;">
                <div class="workspace_body__item accordion__item">
                  <h3
                    @click="openOtherLeasing"
                  >Сторонний лизинг</h3>
                </div>
                <div v-if="data.Lead.project_id" class="workspace_body__item accordion__item">
                  <h3
                    :class="{
                      trade_content__title: true,
                      active: guarantors_dropdown,
                    }"
                    @click="guarantors_dropdown = !guarantors_dropdown"
                  >
                    <span>Поручитель</span>
                    <ArrowDownIcon />
                  </h3>
                  <div class="accordion__content" v-if="guarantors_dropdown">
                    <ul class="tc" v-if="data.Lead.guarantors">
                      <li
                        v-for="(guarantor, index) in data.Lead.guarantors"
                        :key="index"
                      >
                        <ul class="tc__list">
                          <li>
                            <router-link
                              :to="{}"
                              class="tc__list__title"
                              >{{ guarantor.name }}</router-link
                            >
                            <span class="tc__list__subtitle">ИНН {{ guarantor.inn }}</span
                            >
                            <i
                              class="guarantor-icon"
                              @click="showGuarantorModal(data.Lead.project_id, guarantor.inn)"
                            >
                              <NewSettingsIcon />
                            </i>
                            <i
                              class="guarantor-icon"
                              @click.prevent="unpinGuarantor(guarantor.inn)"
                            >
                              <DeleteIcon />
                            </i>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <div class="tc__more">
                      <a
                        href="#"
                        @click.prevent="showAddGuarantorModal"
                        >+ еще поручитель</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="workspace_left__row accordion">
                <div
                  class="workspace_body__item accordion__item"
                  v-if="data.Lead.company && data.Lead.company._id"
                >
                  <h3
                    :class="{
                      trade_content__title: true,
                      active: contact_dropdown,
                    }"
                    @click="contact_dropdown = !contact_dropdown"
                  >
                    <span>Контакт</span>
                    <ArrowDownIcon />
                  </h3>
                  <div class="accordion__content" v-if="contact_dropdown">
                    <ul class="tc" v-if="data.Lead.company">
                      <li
                        v-for="(contact, index) in data.Lead.company.contacts"
                        :key="index"
                      >
                        <ul class="tc__list">
                          <li>
                            <router-link
                              :to="{
                                name: 'contact',
                                params: { id: contact._id },
                              }"
                              class="tc__list__title"
                              >{{ contact.name }}</router-link
                            >
                            <span
                              class="tc__list__subtitle"
                              v-if="contact.post"
                              >{{ contact.post }}</span
                            >
                            <i
                              @click="
                                showContactDataModal(
                                  data.Lead.company._id,
                                  contact,
                                  'update'
                                )
                              "
                            >
                              <NewSettingsIcon />
                            </i>
                            <i
                              @click="
                                hideContact(contact)
                              "
                            >
                              <DeleteIcon />
                            </i>
                          </li>
                          <li v-if="contact.phone.length">
                            <span class="tc__list__title">Телефон</span>
                            <span class="tc__list__subtitle">{{
                              contact.phone[0]
                            }}</span>
                            <i v-if="!callTimeout" @click="callPhone(contact.phone[0])">
                              <PhoneOutIcon />
                            </i>
                            <i v-else style="cursor: wait">
                              X
                            </i>
                          </li>
                          <li v-if="contact.email">
                            <span class="tc__list__title">Почта</span>
                            <span class="tc__list__subtitle">{{
                              contact.email.split(";").join(", ")
                            }}</span>
                            <i></i>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <div class="tc__more">
                      <a
                        href="#"
                        @click.prevent="
                          showContactDataModal(
                            data.Lead.company._id,
                            null,
                            'add'
                          )
                        "
                        >+ еще контакт</a
                      >
                    </div>
                  </div>
                </div>
                <div class="workspace_body__item accordion__item">
                  <h3
                    :class="{
                      trade_content__title: true,
                      active: main_dropdown,
                    }"
                    @click="main_dropdown = !main_dropdown"
                  >
                    <span>Организация</span>
                    <ArrowDownIcon />
                  </h3>
                  <div class="accordion__content" v-if="main_dropdown">
                    <!--                    :elementId="id"-->
                    <!--                    elementType="Lead"-->
                    <!--                    :data="data.Lead.company"-->
                    <ul class="tc" v-if="data.Lead.company">
                      <li>
                        <ul class="tc__list">
                          <li>
                            <router-link
                              :to="{
                                name: 'company',
                                params: { id: data.Lead.company._id },
                              }"
                              class="tc__list__title"
                              >{{ data.Lead.company.name }}</router-link
                            >
                            <span class="tc__list__subtitle"
                              >ИНН {{ data.Lead.company.inn }}</span
                            >
                            <i
                              @click="
                                showCompanyDataModal(
                                  $route.params.id,
                                  data.Lead.company,
                                  'Lead',
                                  null
                                )
                              "
                            >
                              <NewSettingsIcon />
                            </i>
                          </li>
                          <li v-if="data.Lead.company.phone.length">
                            <span class="tc__list__title">Телефон</span>
                            <span class="tc__list__subtitle">{{
                              data.Lead.company.phone[0]
                            }}</span>
                            <i v-if="!callTimeout" @click="callPhone(data.Lead.company.phone[0])">
                              <PhoneOutIcon />
                            </i>
                            <i v-else style="cursor: wait">
                              X
                            </i>
                          </li>
                          <li v-if="data.Lead.company.email">
                            <span class="tc__list__title">Почта</span>
                            <span class="tc__list__subtitle">{{
                              data.Lead.company.email
                            }}</span>
                            <i></i>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="workspace_body__item accordion__item">
                  <h3
                    :class="{
                      trade_content__title: true,
                      active: property_dropdown,
                    }"
                    @click="property_dropdown = !property_dropdown"
                  >
                    <span>Имущество</span>
                    <ArrowDownIcon />
                  </h3>
                  <div class="accordion__content" v-if="property_dropdown">
                    <ul class="tc" v-if="data.Lead.calculators.length">
                      <li>
                        <div class="tc__check"></div>
                        <ul class="tc__list">
                          <li
                            v-for="(calculator, index) in data.Lead.calculators"
                            :key="index"
                          >
                            <span v-if="data.Lead.calculators.length > 1 && data.Lead.status === 'approved'" style="display: inline-block; width: 20px; height: 20px; position: relative; margin-right: 10px;"><input type="radio" v-model="checkedCalc" name="calculatorRadio" checked style="position: absolute; top: -10px; left: 0;" :value="calculator"></span>
                            <span class="tc__list__title">{{
                              calculator.contract || calculator.name || "Без наименования"
                            }}</span>
                            <a v-if="calculator.approved && !calculator.contract "
                              href="#"
                              @click.prevent="
                                checkedCalc = calculator
                                calcModal = true
                              "
                            >
                              + Договор
                            </a>
                            <span class="tc__list__subtitle">{{
                              prettifyPrice(calculator.price)
                            }}</span>
                            <i
                              @click.prevent="showCalcModal(
                                null,
                                'update',
                                calculator.name,
                                calculator,
                                calculator.id
                              )"
                            >
                              <NewSettingsIcon />
                            </i>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <div class="tc__more">
                      <a
                        href="#"
                        class="tc__more__button"
                        @click.prevent="
                          showCalcModal(
                            data.Lead._id,
                            'add',
                            'Новый калькулятор',
                            null
                          )
                        "
                        >еще калькулятор</a
                      >
                    </div>
                  </div>
                </div>
                <!-- <div v-if="['first', 'created'].includes(data.Lead.status)" class="workspace_body__item accordion__item">
                  <h3
                    :class="{
                      trade_content__title: true,
                      active: calc_dropdown,
                    }"
                    @click="calc_dropdown = !calc_dropdown"
                  >
                    <span>Калькуляторы</span>
                    <ArrowDownIcon />
                  </h3>
                  <div class="accordion__content" v-if="calc_dropdown">
                    <input type="text" v-model="calculatorsSearchModel">
                    <ul class="tc">
                      <li>
                        <ul class="tc__list">
                          <li
                            v-for="(calculator, index) in calculators"
                            :key="index"
                          >
                            <span class="tc__list__title">{{
                              calculator.name || "Без наименования"
                            }}</span>
                            <span><a href="#" @click.prevent="bindCalc(calculator.id)">Прикрепить к сделке</a></span>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div> -->
                <div v-if="data.Lead.contracts.length && data.Lead.contracts[0]" class="workspace_body__item accordion__item">
                  <h3
                    :class="{
                      trade_content__title: true,
                      active: contract_dropdown,
                    }"
                    @click="contract_dropdown = !contract_dropdown"
                  >
                    <span>Договоры лизинга</span>
                    <ArrowDownIcon />
                  </h3>
                  <div class="accordion__content" v-if="contract_dropdown">
                    <ul class="tc">
                      <li>
                        <div class="tc__check"></div>
                        <ul class="tc__list">
                          <li
                            v-for="(contract, index) in data.Lead.contracts"
                            :key="index"
                          >
                            <span v-if="data.Lead.contracts.length > 1 && data.Lead.status === 'dl'" style="display: inline-block; width: 20px; height: 20px; position: relative; margin-right: 10px;"><input type="radio" v-model="checkedContract" name="contractRadio" checked style="position: absolute; top: -10px; left: 0;" :value="contract"></span>
                            <span class="tc__list__title">
                              <router-link
                                :to="{
                                  name: 'contract',
                                  params: { id: contract.id },
                                }"
                                class="tc__list__title"
                                >{{ contract.name }}</router-link
                              >
                            </span>
                            <span class="tag" :class="getStatusColor(contract.status)">{{ contract.status }}</span>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="workspace_body__item accordion__item"
                  v-if="
                    data &&
                    data.Lead &&
                    data.Lead.files &&
                    data.Lead.files.length
                  "
                >
                  <h3
                    :class="{
                      trade_content__title: true,
                      active: docs_dropdown,
                    }"
                    @click="docs_dropdown = !docs_dropdown"
                  >
                    <span>Документы</span>
                    <ArrowDownIcon />
                  </h3>
                  <div class="accordion__content" v-if="docs_dropdown">
                    <ul class="tc">
                      <li
                        v-for="(category, index) in groupBy(
                          data.Lead.files,
                          'view'
                        )"
                        :key="index"
                      >
                        <div class="tc__check"></div>
                        <ul class="tc__list">
                          <li
                            :key="index"
                            v-for="(doc, index) in category.items"
                          >
                            <a class="tc__list__title" target="_blank" :href="'https://' + doc.path" v-if="doc.path" download>{{ doc.name }}</a>
                            <!-- <span class="tc__list__title">{{ doc.name }}</span> -->
                            <!-- <span class="tag tag--purple">Тег</span> -->
                            <span class="tc__list__subtitle">{{ doc.ext }}</span>
                            <i></i>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <!-- <div class="tc__more">
                      <a
                        href="#"
                        class="tc__more__button"
                        @click.prevent="showDocsModal"
                        >загрузить документы</a
                      >
                    </div> -->
                    <div class="tc__more">
                      <a
                        @click="loadDocsModal"
                        class="tc__more__button"
                        >загрузить документы</a
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="workspace_body__item accordion__item"
                  v-if="
                    data &&
                    data.Lead &&
                    dataList.notes &&
                    ['xs', 'sm', 'xsm', 'lg', 'xlg'].includes($mq)
                  "
                >
                  <h3
                    :class="{
                      trade_content__title: true,
                      active: comments_dropdown,
                    }"
                    @click="comments_dropdown = !comments_dropdown"
                  >
                    <span>Комментарии и задачи</span>
                    <ArrowDownIcon />
                  </h3>
                </div>
              </div>
            </aside>
            <aside class="crm__aside" v-if="['xs', 'sm', 'xsm', 'lg', 'xlg'].includes($mq) && comments_dropdown">
              <div
                class="workspace_body__item accordion__item"
                v-if="
                  data &&
                  data.Lead &&
                  dataList.notes &&
                  comments_dropdown
                "
              >
                <h3
                  :class="{
                    trade_content__title: true,
                    active: comments_dropdown,
                  }"
                  @click="comments_dropdown = !comments_dropdown"
                >
                  <span>Комментарии и задачи</span>
                  <ArrowDownIcon />
                </h3>
              </div>
            </aside>
            <template v-if="$mq !== 'xl'">
              <LsgNotes
                v-if="data && data.Lead && dataList.notes && comments_dropdown"
                :scroll="false"
                :key="noteRerenderKey"
                :isRightPage="true"
                elementType="Lead"
                :elementId="data.Lead._id"
                :elementEmail="data.Lead.company.email"
                :notes="tradeData.notes || []"
                :tasks="tradeData.tasks || []"
                :activeTasks="tradeData.activeTasks || []"
                :position="position1"
                :onLoading="onLoading"
                v-bind:position1.sync="position1"
              ></LsgNotes>
            </template>
            <template v-else>
              <LsgNotes
                v-if="data && data.Lead && dataList.notes"
                :scroll="true"
                :key="noteRerenderKey"
                :isRightPage="true"
                elementType="Lead"
                :elementId="data.Lead._id"
                :elementEmail="data.Lead.company.email"
                :notes="tradeData.notes || []"
                :tasks="tradeData.tasks || []"
                :activeTasks="tradeData.activeTasks || []"
                :position="position1"
                :onLoading="onLoading"
                v-bind:position1.sync="position1"
              ></LsgNotes>
            </template>
            <LsgTasks
              v-if="dataList.tasks"
              :id="data.Lead.project_id"
            />
          </div>
        </div>
      </div>
    </template>
  </ApolloQuery>
</template>

<script>
import moment from 'moment'
import _ from 'lodash'
import LsgNotes from '../../components/ui/LsgNotes'
import LsgTasks from '../../components/ui/LsgTasks'
import CreateContractModal from '../../components/layouts/modals/components/CreateContractModal'
import BIND_CALC from '@/graphql/mutations/BindCalc.gql'
import CREATE_PROJECT from '@/graphql/mutations/CreateProject.gql'
import ADD_GUARANTOR from '@/graphql/mutations/AddGuarantor.gql'
import UNPIN_GUARANTOR from '@/graphql/mutations/UnpinGuarantor.gql'
import START_PROJECT from '@/graphql/mutations/StartProject.gql'
// import MAKE_CALL from '@/graphql/mutations/MakeCall.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import NewSettingsIcon from '../../components/svg/new/Settings'
import ArrowDownIcon from '../../components/svg/arrow_down'
import DownloadNewIcon from '../../components/svg/DownloadNewIcon'
import UploadNewIcon from '../../components/svg/UploadNewIcon'
import TransferIcon from '../../components/svg/TransferActIcon'
import PhoneOutIcon from '../../components/svg/new/PhoneOutIcon'
import CarIcon from '../../components/svg/CarIcon'
import DeleteIcon from '@/components/svg/DeleteIcon.vue'
// import TruckIcon from '../../components/svg/TruckIcon'
// import TractorIcon from '../../components/svg/TractorIcon'
// import FactoryIcon from '../../components/svg/FactoryIcon'
// import HouseIcon from '../../components/svg/HouseIcon'
import ManIcon from '../../components/svg/ManIcon'
import BuildingFactoryIcon from '../../components/svg/BuildingFactoryIcon'
import SwitcherAlt from '@/components/ui/SwitcherAlt'
import ContractFilesModal from '../../components/layouts/modals/components/ContractFilesModal'
import ContractBuyoutModal from '../../components/layouts/modals/components/ContractBuyoutModal.vue'
import gql from 'graphql-tag'
import ALL_CALCS from '@/graphql/queries/AllCalculators.gql'
import HIDE_CONTACT from '@/graphql/mutations/HideContact.gql'

// import ExternalIcon from '../../components/svg/ExternalIcon'

const _graphQlHelper = new GraphQLHelper()
export default {
  name: 'Trade',
  components: {
    // ExternalIcon,
    TransferIcon,
    UploadNewIcon,
    DownloadNewIcon,
    PhoneOutIcon,
    ArrowDownIcon,
    NewSettingsIcon,
    LsgNotes,
    LsgTasks,
    SwitcherAlt,
    CreateContractModal,
    ContractFilesModal,
    ManIcon,
    CarIcon,
    // TruckIcon,
    // TractorIcon,
    // FactoryIcon,
    // HouseIcon,
    BuildingFactoryIcon,
    ContractBuyoutModal,
    DeleteIcon
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      title: 'Сделка',
      callTimeout: false,
      serviceModal: false,
      serviceOriginEnum: [
        {
          label: 'Поставщик',
          field: 'provider'
        },
        {
          label: 'Брокер',
          field: 'broker'
        },
        {
          label: 'Сотрудник ЛП',
          field: 'lp'
        },
        {
          label: 'Сотрудник ЛК',
          field: 'lk'
        },
        {
          label: 'Колл-центр',
          field: 'callcenter'
        },
        {
          label: 'Без агента',
          field: 'without'
        }
      ],
      service: {
        origin: null,
        comment: ''
      },
      dataList: {
        notes: true,
        email: false,
        tasks: false
      },
      form: {
        name: '',
        responsible_user: {
          _id: null,
          name: null
        },
        tags: []
      },
      products: [
        'Недвижимость',
        'Стандарт',
        'СуперЭкспресс'
        // 'СуперЭкспрессРФ'
      ],
      selectProduct: 'СуперЭкспресс',
      createLoad: false,
      main_dropdown: false,
      property_dropdown: false,
      comments_dropdown: false,
      calc_dropdown: false,
      docs_dropdown: false,
      company_dropdown: false,
      contact_dropdown: false,
      contract_dropdown: false,
      guarantors_dropdown: false,
      guarantor: {
        inn: null
      },
      trade_num: '#19445353',
      subMenu: false,
      isCreateCalculator: false,
      isCreateContact: false,
      leftSwitcher: false,
      calcModal: false,
      noteRerenderKey: 0,
      lastPosition: 0,
      position1: 1000000,
      tradeData: null,
      onLoading: true,
      contracts: [],
      checkedContract: null,
      checkedCalc: null,
      limit: 10,
      filesModal: false,
      isDownload: false,
      buyoutModal: this.$store.state.buyoutModalToggle || false,
      actModal: false,
      addGuarantorModal: false,
      disableGuarantorButton: false,
      calculators: [],
      calculatorsSearch: ''
    }
  },
  computed: {
    rerenderKey () {
      return this.$store.state.data.trades.rerenderKey
    },
    isValidForCreate () {
      const valid = Boolean(!this.createLoad && this.service.comment.length >= 10 && Boolean(this.service.origin))
      return valid
    },
    isChanged () {
      return (
        this.form.responsible_user._id ===
          this.$store.state.Lead.responsible_user._id &&
        this.form.name === this.$store.state.Lead.name &&
        this.form.name !== '' &&
        JSON.stringify(this.form.tags) ===
          JSON.stringify(this.$store.state.Lead.tags.map((item) => item.id))
      )
    },
    calculatorsSearchModel: {
      get () {
        return this.calculatorsSearch
      },
      set (value) {
        this.calculatorsSearch = value
        this.getCalculators()
      }
    }
  },
  beforeMount () {
    this.getCalculators()
  },
  apollo: {
    apolloData () {
      return {
        query: gql`
          query {
            maketList
            documentTypes
          }
        `,
        update: (data) => {
          this.$nextTick(() => {
            this.$store.state.loadedPages.push({ title: this.$route.meta.title, route: this.$route.name })
          })
          return data
        }
      }
    }
  },
  methods: {
    hideContact (contact) {
      this.$apollo
        .mutate({
          mutation: HIDE_CONTACT,
          variables: {
            id: contact._id
          },
          update: (_, { data }) => {
            if (data && data.hideContact) {
              this.$store.commit('incrementTradeRerenderKey')
              this.$notify({
                group: 'lsg-notify',
                text: `Контакт ${data.hideContact.name} скрыт`
              })
            }
          }
        })
    },
    bindCalc (calcId) {
      this.$apollo
        .mutate({
          mutation: BIND_CALC,
          variables: {
            leadId: this.$route.params.id,
            calcId: calcId
          },
          update: (_, { data }) => {
            if (data) {
              this.$notify({
                group: 'lsg-notify',
                text: 'Калькулятор добавлен'
              })
            }
          }
        })
    },
    showGuarantorModal (projectId, inn) {
      if (this.$store.state.data.trades.checkTradeModal === true) {
        this.$notify({
          group: 'lsg-notify',
          duration: 10000,
          text: 'Сначала завершите текущую проверку реквизитов'
        })
      } else {
        this.$store.state.data.trades.checkGuarantorModal = true
        this.$store.state.data.trades.guarantorData = { inn, id: projectId }
      }
    },
    openOtherLeasing () {
      this.$store.state.data.companies.otherLeasingModal = true
    },
    switchTasks (isCheck) {
      if (isCheck) {
        this.dataList.notes = false
        this.dataList.email = false
        this.dataList.tasks = true
      } else {
        this.dataList.tasks = false
        this.dataList.email = false
        this.dataList.notes = true
      }
    },
    loadDocsModal () {
      this.$store.state.data.trades.checkTradeModal = true
      this.$store.state.data.trades.loadCheckTradeData = true
      this.$store.state.step = 4
    },
    showCheckTradeModal (status) {
      if (this.$store.state.data.trades.checkTradeModal === true) {
        this.$notify({
          group: 'lsg-notify',
          duration: 10000,
          text: 'Сначала завершите текущую проверку реквизитов'
        })
      } else if (this.$store.state.Lead.calculators && this.$store.state.Lead.calculators.length === 0) {
        this.$notify({
          group: 'lsg-notify',
          duration: 10000,
          text: 'В сделке необходимо наличие калькулятора'
        })
        this.showCalcModal(
          this.$store.state.Lead._id,
          'add',
          'Новый калькулятор',
          null
        )
      } else {
        this.$store.state.data.trades.status = status
        this.$store.state.data.trades.checkTradeModal = true
        this.$store.state.data.trades.loadCheckTradeData = true
      }
    },
    showCheckCalcTradeModal () {
      if (this.$store.state.data.trades.checkTradeModal === true) {
        this.$notify({
          group: 'lsg-notify',
          duration: 10000,
          text: 'Сначала завершите текущую проверку реквизитов'
        })
      } else {
        this.$store.state.data.trades.checkTradeModal = true
        this.$store.state.data.trades.loadCheckTradeData = true
        this.$store.state.step = 3
      }
    },
    async getCalculators () {
      await this.$apollo.mutate({
        mutation: ALL_CALCS,
        variables: {
          q: this.calculatorsSearch
        },
        update: (store, { data }) => {
          if (data) {
            this.calculators = data.allCalculators.slice(0, 10)
          }
        }
      }).catch(error => {
        this.loadCalc = false
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    showMainDataModal () {
      this.$store.state.data.trades.mainDataModal = !this.$store.state.data.trades
        .mainDataModal
    },
    showServiceModal () {
      this.serviceModal = true
    },
    showAddGuarantorModal () {
      this.addGuarantorModal = !this.addGuarantorModal
    },
    unpinGuarantor (inn) {
      this.$notify({
        group: 'lsg-notify',
        text: 'Отправка данных...',
        duration: -1
      })
      this.disableGuarantorButton = true
      this.$apollo
        .mutate({
          mutation: UNPIN_GUARANTOR,
          variables: {
            project_id: this.$store.state.Lead.project_id,
            inn
          },
          update: (_, { data }) => {
            if (data) {
              this.disableGuarantorButton = false
              this.$notify({
                group: 'lsg-notify',
                clean: true
              })
              this.$notify({
                group: 'lsg-notify',
                text: 'Поручитель успешно откреплён'
              })
              this.$store.commit('incrementTradeRerenderKey')
            }
          }
        })
        .catch((error) => {
          this.disableGuarantorButton = false
          this.$notify({
            group: 'lsg-notify',
            clean: true
          })
          _graphQlHelper.graphQLErrorMessages(error).forEach((item) => {
            this.$notify({
              group: 'lsg-notify-support',
              title: 'Сообщить в поддержку',
              text: `Нажав на это сообщение, вы отправите отчёт об ошибке в тех.поддержку`,
              type: 'info',
              closeOnClick: true,
              data: {
                info: {
                  project: 'Личный кабинет Leasing-trade',
                  name: this.$store.state.me.name,
                  email: this.$store.state.me.email,
                  group_id: this.$store.state.me.group.id,
                  role: this.$store.state.me.role,
                  localTime: new Date(),
                  system: navigator.userAgent,
                  url: window.location.href,
                  window: `${window.window.screen.availWidth}px ${window.window.screen.availHeight}px`,
                  error: item,
                  comment: 'Ошибка при откреплении поручителя'
                },
                style: 'cursor: pointer'
              }
            })
          })
        })
    },
    addGuarantor () {
      this.$notify({
        group: 'lsg-notify',
        text: 'Отправка данных...',
        duration: -1
      })
      this.disableGuarantorButton = true
      this.$apollo
        .mutate({
          mutation: ADD_GUARANTOR,
          variables: {
            project_id: this.$store.state.Lead.project_id,
            inn: this.guarantor.inn
          },
          update: (_, { data }) => {
            if (data) {
              this.disableGuarantorButton = false
              this.$notify({
                group: 'lsg-notify',
                clean: true
              })
              this.$notify({
                group: 'lsg-notify',
                text: 'Поручитель успешно добавлен'
              })
              this.$store.commit('incrementTradeRerenderKey')
            }
          }
        })
        .catch((error) => {
          this.disableGuarantorButton = false
          _graphQlHelper.graphQLErrorMessages(error).forEach((item) => {
            this.$notify({
              group: 'lsg-notify',
              clean: true
            })
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
            this.$notify({
              group: 'lsg-notify-support',
              title: 'Сообщить в поддержку',
              text: `Нажав на это сообщение, вы отправите отчёт об ошибке в тех.поддержку`,
              type: 'info',
              closeOnClick: true,
              data: {
                info: {
                  project: 'Личный кабинет Leasing-trade',
                  name: this.$store.state.me.name,
                  email: this.$store.state.me.email,
                  group_id: this.$store.state.me.group.id,
                  role: this.$store.state.me.role,
                  localTime: new Date(),
                  system: navigator.userAgent,
                  url: window.location.href,
                  window: `${window.window.screen.availWidth}px ${window.window.screen.availHeight}px`,
                  error: item,
                  comment: 'Ошибка при создании поручителя'
                },
                style: 'cursor: pointer'
              }
            })
          })
        })
    },
    showContactDataModal (companyId, data, type) {
      this.$store.state.data.trades.contactDataModal = !this.$store.state.data
        .trades.contactDataModal
      this.$store.state.data.trades.contactDataModalProps.companyId = companyId
      this.$store.state.data.trades.contactDataModalProps.data = data
      this.$store.state.data.trades.contactDataModalProps.type = type
    },
    showCompanyDataModal (elementId, data, elementType, isCanceled) {
      this.$store.state.data.trades.companyDataModal = !this.$store.state.data
        .trades.companyDataModal
      this.$store.state.data.trades.companyDataModalProps.companyId = elementId
      this.$store.state.data.trades.companyDataModalProps.data = data
      this.$store.state.data.trades.companyDataModalProps.type = elementType
      this.$store.state.data.trades.companyDataModalProps.type = isCanceled
    },
    showCalcModal (leadId, type, header, data, calcId) {
      this.$store.state.data.trades.fullCalcModal = !this.$store.state.data.trades
        .fullCalcModal
      this.$store.state.data.trades.fullCalcDataModalProps.leadId = leadId
      this.$store.state.data.trades.fullCalcDataModalProps.calcId = calcId
      this.$store.state.data.trades.fullCalcDataModalProps.type = type
      this.$store.state.data.trades.fullCalcDataModalProps.header = header
      this.$store.state.data.trades.fullCalcDataModalProps.data = data
    },
    showDocsModal () {
      this.$store.state.data.trades.docsModal = !this.$store.state.data.trades
        .docsModal
    },
    prettifyPrice (price) {
      if (price) {
        return Number(price).toLocaleString()
      }
      return 0
    },
    async callPhone (phone) {
      if (!phone || !this.$store.state.me.workphone) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Не указан телефон'
        })
        return
      }
      const callWindow = window.open(`http://192.168.10.3/amocrm/amocrm.php?_login=amocrm&_secret=mWYd1BU9hmWuezov&_action=call&to=${phone}&as=Amocrm&from=${this.$store.state.me.workphone}&rand=0.9550694509986963`)
      setTimeout(() => {
        callWindow.close()
      }, 1_000)
    },
    getStatusColor (status) {
      switch (status) {
        case 'first':
          return 'tag--blue'
        case 'meeting':
          return 'tag--purple'
        case 'documents':
          return 'tag--yellow'
        case 'created':
          return 'tag--dark-blue'
        case 'closed':
          return 'tag--red'
        case 'decision':
          return 'tag--orange'
        case 'approved':
          return 'tag--green'
        case 'Готов к финансированию':
          return 'tag--purple'
        case 'Профинансирован':
          return 'tag--green'
        case 'Согласование':
          return 'tag--orange'
        case 'Действует':
          return 'tag--dark-blue'
      }
    },
    groupBy (array, string) {
      let group = _.groupBy(array, string)
      return Object.keys(group).map((key) => ({
        title: key,
        items: group[key]
      }))
    },
    onTagsInput (tags) {
      this.form.tags = tags.map((item) => item.value)
    },
    saveTrade () {
      this.$store
        .dispatch('updateLead', {
          context: this,
          data: {
            id: this.$store.state.Lead._id,
            status: this.$store.state.Lead.status,
            name: this.form.name,
            responsible_user_id: this.form.responsible_user._id,
            tags: this.form.tags
          }
        })
        .then(() => {
          this.$store.commit('incrementTradeRerenderKey')
        })
    },
    async createProject (id) {
      this.createLoad = true
      await this.$apollo
        .mutate({
          mutation: CREATE_PROJECT,
          variables: {
            id,
            product: this.selectProduct,
            origin: this.service.origin,
            comment: this.service.comment
          },
          update: (_, { data }) => {
            if (data && data.createProject) {
              this.$router.push({ name: 'trade', params: { id } })
              this.$store.commit('incrementTradeRerenderKey')
              this.$notify({
                group: 'lsg-notify',
                text: 'Данные успешно сохранены'
              })
            }
          }
        })
        .catch((error) => {
          _graphQlHelper.graphQLErrorMessages(error).forEach((item) => {
            this.createLoad = false
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
            this.$notify({
              group: 'lsg-notify-support',
              title: 'Сообщить в поддержку',
              text: `Нажав на это сообщение, вы отправите отчёт об ошибке в тех.поддержку`,
              type: 'info',
              closeOnClick: true,
              data: {
                info: {
                  project: 'Личный кабинет Leasing-trade',
                  name: this.$store.state.me.name,
                  email: this.$store.state.me.email,
                  group_id: this.$store.state.me.group.id,
                  role: this.$store.state.me.role,
                  localTime: new Date(),
                  system: navigator.userAgent,
                  url: window.location.href,
                  window: `${window.window.screen.availWidth}px ${window.window.screen.availHeight}px`,
                  error: item,
                  comment: 'Ошибка при создании проекта'
                },
                style: 'cursor: pointer'
              }
            })
          })
        })
        .finally(() => {
          this.serviceModal = false
        })
    },
    async startProject (id) {
      this.createLoad = true
      this.$notify({
        group: 'lsg-notify',
        text: 'Отправка данных...',
        duration: -1
      })
      await this.$apollo
        .mutate({
          mutation: START_PROJECT,
          variables: {
            id
          },
          update: (_, { data }) => {
            this.createLoad = false
            this.$notify({
              group: 'lsg-notify',
              clean: true
            })
            if (data && data.startProject) {
              this.$store.commit('incrementTradeRerenderKey')
              this.$notify({
                group: 'lsg-notify',
                text: 'Проект запущен на рассмотрение'
              })
            } else {
              this.$notify({
                group: 'lsg-notify',
                text: 'Ошибка при запуске процесса'
              })
              this.$notify({
                group: 'lsg-notify-support',
                title: 'Сообщить в поддержку',
                text: `Нажав на это сообщение, вы отправите отчёт об ошибке в тех.поддержку`,
                type: 'info',
                closeOnClick: true,
                data: {
                  info: {
                    project: 'Личный кабинет Leasing-trade',
                    name: this.$store.state.me.name,
                    email: this.$store.state.me.email,
                    group_id: this.$store.state.me.group.id,
                    role: this.$store.state.me.role,
                    localTime: new Date(),
                    system: navigator.userAgent,
                    url: window.location.href,
                    window: `${window.window.screen.availWidth}px ${window.window.screen.availHeight}px`,
                    error: 'Ошибка при запуске процесса',
                    comment: 'Ошибка при запуске процесса'
                  },
                  style: 'cursor: pointer'
                }
              })
            }
          }
        })
        .catch((error) => {
          this.createLoad = false
          this.$notify({
            group: 'lsg-notify',
            clean: true
          })
          _graphQlHelper.graphQLErrorMessages(error).forEach((item) => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
            this.$notify({
              group: 'lsg-notify-support',
              title: 'Сообщить в поддержку',
              text: `Нажав на это сообщение, вы отправите отчёт об ошибке в тех.поддержку`,
              type: 'info',
              closeOnClick: true,
              data: {
                info: {
                  project: 'Личный кабинет Leasing-trade',
                  name: this.$store.state.me.name,
                  email: this.$store.state.me.email,
                  group_id: this.$store.state.me.group.id,
                  role: this.$store.state.me.role,
                  localTime: new Date(),
                  system: navigator.userAgent,
                  url: window.location.href,
                  window: `${window.window.screen.availWidth}px ${window.window.screen.availHeight}px`,
                  error: item,
                  comment: 'Ошибка при запуске процесса'
                },
                style: 'cursor: pointer'
              }
            })
          })
        })
    },
    onUserChange (e) {
      if (e) {
        this.form.responsible_user._id = e.value
        this.form.responsible_user.name = e.label
      } else {
        this.form.responsible_user._id = null
        this.form.responsible_user.name = null
      }
    },
    getStatus (status) {
      if (this.$store.state.leadStatusListClear) {
        for (let i = 0; i < this.$store.state.leadStatusListClear.length; i++) {
          if (this.$store.state.leadStatusListClear[i].value === status) {
            return this.$store.state.leadStatusListClear[i].title
          }
        }
      }
    },
    getDate (datetime) {
      return datetime ? moment(String(datetime)).format('DD.MM.YYYY') : ''
    },
    doneLoadingLead ({ data }) {
      if (data.Lead) {
        this.$store.state.Lead = data.Lead
        this.$store.state.data.companies.inn = data.Lead.company.inn
        this.tradeData = data.Lead
        if (data.Lead && data.Lead.name) {
          this.form.name = data.Lead.name
        } else {
          this.form.name =
            data.Lead.company && data.Lead.company.name
              ? data.Lead.company.name
              : ''
        }
        this.form.responsible_user = Object.assign(
          {},
          data.Lead.responsible_user
        )
      }
      if (data.Lead.calculators && data.Lead.calculators.length > 0) {
        this.checkedCalc = data.Lead.calculators[0]
      }
      if (data.Lead.contracts && data.Lead.contracts > 0) {
        this.contracts = data.Lead.contracts
        this.checkedContract = data.Lead.contracts[0]
      }
      if ((this.tradeData._allNotesMeta + this.tradeData.tasks.length) <= this.limit) this.onLoading = false
      this.$nextTick(() => {
        this.noteRerenderKey++
      })
    }
  },
  watch: {
    position1: function () {
      this.limit = this.limit + 10
    }
  }
}
</script>

<style lang="stylus">
  .btn--trade {
    padding none !important

    +below(380px) {
      padding none !important
    }
  }
</style>

<style lang="stylus">
.modal__attention__textarea > textarea {
  max-width 300px
}

.modal__attention__origin {
  display grid
  grid-template-columns 1fr 1fr
  grid-column-gap 10px
  grid-row-gap 10px
  width 100%
  margin-bottom 20px

  +below(768px) {
    grid-template-columns 1fr
  }
}

.modal__origin__item {
  display flex
  align-items center
  flex-wrap nowrap
}

.modal__origin__checkbox {
  margin-right 10px
  max-width 15px
  max-height 15px
}

.modal__origin__label {
  display inline-block
  white-space nowrap
  color black
}

.item__switch {
  display flex
  flex-direction row
  flex-wrap wrap
  justify-content center
  align-items center
  margin 0 10px

  > .switcher {
    margin-bottom -5px
    margin-right 10px
  }

  > span {
    align-text center

    +below(768px) {
      display none
    }
  }
}

.lsg-trade {
  width: 100%;
}

.crm {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 -16px;
  width: 100%;

  +below(768px) {
    width: calc(100% + 42px);
  }

  +below(560px) {
    flex-direction: column;
  }

  +below(540px) {
    margin-bottom: 60px;
  }

  &__div {
    margin: 0 4px;
  }

  &__main {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  &__aside {
    display: flex;
    flex-direction: column;
    max-width: 420px;
    width: 100%;
    height: calc(100vh - 195px);
    margin: 0 16px;
    background: $white;
    border: 1px solid rgba($darkgray, 0.3);
    box-sizing: border-box;
    box-shadow: 0 7px 30px rgba($black, 0.06);
    border-radius: 10px;
    padding: 20px;
    overflow-y: auto;

    +below(768px) {
      max-width: 50%;
      margin-right: 0;
    }

    +below(560px) {
      max-width: 100%;
      height: auto;
      margin: 0 16px;
      box-sizing: border-box;
      width: auto;
    }
  }

  &__notes {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: calc(100% - 420px);
    width: 100%;
    margin: 0 -16px;
    position: relative;
    padding: 0 11px;

    +below(768px) {
      max-width: 50%;
      margin: 0;
      padding: 0;
    }

    +below(560px) {
      max-width: 100%;
    }

    &__overdue {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 10px 32px;
      absolute: left top;
      width calc(100% - 28px)
      background: $white;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      transform: translateY(-100%);
      margin: 0 14px;
      border-bottom: 1px solid $darkgray;
      box-shadow: 0 7px 30px rgba($black, 0.06);
      z-index: 1;

      +below(560px) {
        display: none;
      }

      &__icon {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      &__div {
        margin-left: 20px;
        margin-right: 5px;
      }

      &__list {
        padding: 0;
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        color: $c7A;
        margin: -4px 0 -4px 20px;

        li {
          margin: 4px 0;
        }
      }
    }

    &__send {
      background: none;
      border: none;
      absolute: right 16px bottom 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    &__drag {
      background: none;
      border: none;
      absolute: right 60px bottom 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 0 !important
    }

    &__cancel {
      absolute: right 64px bottom 19px;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: $blue;
    }

    &__div {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: $c4E555A;
    }

    &__list {
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      width: 100%;
      height: calc(100vh - 350px);
      overflow: hidden;
      padding: 0 16px 200px;
      margin-bottom: 8px;

      +below(560px) {
        height: auto;
        margin-top: 24px;
        padding-bottom: 10px;
      }
    }

    &__form {
      background: $white;
      border: 1px solid $darkgray;
      box-sizing: border-box;
      border-radius: 10px;
      margin: 0 16px;
      position: relative;

      &--deminished {
        margin: 0;
        border: 0;

        .mx-input {
          background: transparent !important;
        }
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 0 14px;
        // height 1rem

        & .mx-input-wrapper input {
          background: transparent !important;
        }
      }

      &__body {
        textarea {
          border: none;
          resize: none;
          height: 100%;
          display: flex;
          border-radius: 0 0 10px 10px;
        }
      }

      select {
        border: none;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: $blue;
        width: fit-content !important;
        appearance: none;
        background: none;
        padding: 6px 8px;
        height: auto;
        box-shadow: none !important;
        outline: none !important;
      }

      .mx-datepicker {
        width: auto;
      }

      .mx-input {
        border: none !important;
        height: auto !important;
        font-weight: 500 !important;
        font-size: 12px !important;
        line-height: 15px !important;
        color: $blue !important;
        width: fit-content !important;
        box-shadow: none !important;
      }

      .vs--single.vs--open {
        .vs__selected {
          position: relative;
        }
      }

      .v-select {
        width: fit-content;

        .vs__dropdown-toggle {
          padding: 0;
          border: none;
        }

        .vs__selected {
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: $blue;
        }

        .vs__search {
          padding: 0;
          margin: 0;
          height: 100%;
        }
      }
    }

    &__type {
      display: inline-flex;
      position: relative;
      user-select: none;

      &__selected {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: $blue;
        padding: 6px 8px;
        cursor: pointer;
      }

      &__list {
        absolute: left bottom;
        padding: 0;
        transform: translateY(100%);
        background: $white;
        border: 1px solid $pinky;
        box-sizing: border-box;
        box-shadow: 0 10px 30px rgba($c4E555A, 0.17);
        border-radius: 0 0 10px 10px;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        z-index: 999;
        overflow: hidden;

        li {
          padding: 6px 8px;
          width: 100%;
          color: $c4E555A;
          background: $white;
          cursor: pointer;

          &:hover {
            background: $orange;
            color: $white;
          }
        }
      }
    }
  }

  &__note {
    background: $white;
    border: 1px solid rgba($darkgray, 0.3);
    box-sizing: border-box;
    box-shadow: 0 7px 30px rgba($black, 0.06);
    border-radius: 10px;
    padding: 10px 20px;
    width: 100%;
    margin-bottom: 8px;
    flex-shrink: 0;

    &__close-outdated {
      display: flex;

      button {
        margin-left: 20px;
      }
    }

    &--red {
      background: rgba($red, 0.1);
      border: 1px solid rgba($darkgray, 0.3);
      width: calc(100% - 28px);
      margin: 10px auto 8px;

      .crm__note__date, .crm__note__manager, .crm__div, .crm__note__responsible {
        color: $red;
        font-weight: bold;
      }

      .crm__note__icon svg {
        path[stroke] {
          stroke: $red;
        }

        path[fill] {
          fill: $red;
        }
      }
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      color: $c7A;
      padding-bottom: 10px;
      border-bottom: 1px solid $pinky;
      margin-bottom: 10px;

      &__info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
      }

      &__actions {
        display: flex;
        align-items: center;
        margin: 0 -6px;
      }
    }

    &__action {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      height: 22px;
      padding: 0 6px;

      svg {
        width: 100%;
        height: auto;
      }
    }

    &__icon {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;

      svg {
        width: 100%;
        height: 100%;

        path[stroke] {
          stroke: $orange;
        }

        path[fill] {
          fill: $orange;
        }
      }

      &--green {
        svg {
          path[stroke] {
            stroke: $darkgreen;
          }

          path[fill] {
            fill: $darkgreen;
          }
        }
      }
    }

    &__date {
      margin-right: 15px;
    }

    &__body {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      align-items: stretch;
      font-size: 14px;

      span, strong, p {
        font-size: 14px;
      }

      p .crm__note__text {
        margin-right: 4px;
      }
    }

    &__text {
      font-size: 0.9em;
      line-height: normal;
    }

    &__footer {
      padding-top: 10px;
      border-top: 1px solid $pinky;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      color: $c7A;

      .btn {
        padding: 6px 8px;
        min-height: auto;
        margin: 0 16px;
        border-radius: 5px;
      }
    }
  }
}
button[disabled] {
  border 1px solid #999999
  background-color #cccccc
  color #666666
  pointer-events none
}
.lead-rating, .lead-accreditation, .lead-liquidity {
  display: inline-block;
  height: 30px;
  width: 30px;
  margin-left: 10px;

    /* padding: 5px; */
  > svg {
    height: inherit;
    width: inherit;
    color: #231F20;
    fill: #231F20;

    path[stroke-width] {
      stroke-width 5
    }

    path[stroke] {
      stroke #231F20
    }

    path[fill] {
      fill #231F20
    }
  }
}
.lead-rating--green, .lead-accreditation--green, .lead-liquidity--green {
  > svg {
    color: #219653;
    fill: #219653;
  }
}
.lead-accreditation--red, .lead-rating--red, .lead-liquidity--red {
  > svg {
    color: #FF0000;
    fill: #FF0000;
  }
}
.lead-accreditation--yellow, .lead-rating--yellow, .lead-liquidity--yellow {
  > svg {
    color: #FF8000;
    fill: #FF8000;
  }
}
.guarantor-icon {
  svg {
    path[stroke] {
      stroke $black !important
    }

    path[fill] {
      fill $black !important
    }
  }
}
</style>

<template>
  <div class="modal" v-if="buyoutModal">
    <div class="modal__back" @click="$emit('switchModal')"></div>
    <div class="modal__body trade__modal--clamp">
      <form class="trade__modal" @submit.prevent="tryBuyout" autocomplete="off">
        <div class="trade__modal__header">
          <h1>{{ title }}</h1>
        </div>
        <div class="trade__modal__body" style="margin-bottom:0px; display:flex; flex-direction: row; justify-content: space-around; flex-wrap: wrap;">
          <div v-if="$store.state.me.role !== 'partner'" class="ct__input radio-dp" style="flex-direction:column;align-items: center;justify-content: space-around; width: 250px">
            <label>
              <input type="radio" name="buyoutSwitcher" v-model="full" :value="false">
              <span>Частично-досрочное погашение</span>
            </label>
            <label>
              <input type="radio" name="buyoutSwitcher" v-model="full" :value="true">
              <span>Полное досрочное погашение</span>
            </label>
          </div>
          <div v-if="$store.state.me.role === 'partner' && !full" class="ct__input" style="flex-direction:column;align-items: flex-start;justify-content: space-around; width: 250px">
              <label>Размер платежа</label>
              <input
                placeholder="Размер платежа"
                :type="indicatorAmount ? 'number' : 'text'"
                v-model="amountModel"
                style="width:200px;"
                @focus="indicatorAmount = true"
                @blur="indicatorAmount = false"
              >
          </div>
          <div v-else-if="!full" class="ct__input" style="flex-direction:column;align-items: center;justify-content: space-around; width: 250px">
              <label>Размер платежа</label>
              <input
                placeholder="Размер платежа"
                :type="indicatorAmount ? 'number' : 'text'"
                v-model="amountModel"
                style="width:200px;"
                @focus="indicatorAmount = true"
                @blur="indicatorAmount = false"
              >
          </div>
          <div v-if="$store.state.me.role === 'partner' && full" class="ct__input" style="flex-direction:column;align-items: flex-start;justify-content: space-around;width: 250px">
              <label>Дата платежа</label>
              <input placeholder="Дата платежа" type="date" v-model="date" style="width:200px;">
          </div>
          <div v-else-if="full" class="ct__input" style="flex-direction:column;align-items: center;justify-content: space-around;width: 250px">
              <label>Дата платежа</label>
              <input placeholder="Дата платежа" type="date" v-model="date" style="width:200px;">
          </div>
          <div class="ct__input" style="flex-direction:column;align-items: flex-start;justify-content: space-around;width: 250px">
            <label>Размер скидки</label>
            <label>{{ prettifyPrice(result.discount)}}</label>
            <!-- <label>Размер экономии</label>
            <label class="tag tag--orange">{{ result.economy ? prettifyPrice(result.economy): 0}}</label> -->
          </div>
        </div>
        <!-- <div class="ct__input" style="flex-direction:row;align-items: center;justify-content: space-around; flex-wrap: wrap;" v-if="result.discount && $store.state.me.role !== 'partner'">
          <label>Размер скидки</label>
          <label>{{ prettifyPrice(result.discount)}}</label>
          <label>Размер экономии</label>
          <label>{{ prettifyPrice(result.economy)}}</label>
        </div> -->
        <div class="_col-2 contract__graphic-table">
          <vue-good-table :sort-options="{ enabled: false }" :fixed-header="true" :columns="paymentColumns" :pagination-options="{enabled: false}" :rows="result.payments.map((pay, i) => {pay.number = i + 1; return pay})" max-height="600px" compactMode>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'number'">{{ props.row.number }}</span>
              <span v-if="props.column.field === 'amount'">{{ prettifyPrice(props.row.amount) }}</span>
              <span v-if="props.column.field === 'amountafter'">{{ prettifyPrice(props.row.amountafter) }}</span>
            </template>
            <div slot="emptystate">Данных нет</div>
          </vue-good-table>
        </div>
        <div class="trade__modal__footer">
          <button type="modal__attention__btn" class="btn" :disabled="!result.dl || full" v-if="!result.doc_number" @click="save = true; tryBuyout">Скачать счет-оферту</button>
          <button type="submit" class="btn" style="margin-left: 5px" :disabled="!amount && !full">Расчитать</button>
          <button class="modal__attention__btn" @click="$emit('switchModal'); buyoutModalDisactivation()">Закрыть</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import GraphQLHelper from '@/helpers/GraphQLHelper'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'CreateContractModal',
  props: [ 'buyoutModal', 'contractId', 'contractName' ],
  data () {
    return {
      title: 'Досрочное погашение',
      loading: false,
      indicatorAmount: false,
      amount: 0,
      date: new Date().toISOString().split('T')[0],
      result: { payments: [] },
      save: false,
      full: false,
      paymentColumns: [
        {
          label: '№',
          field: 'number'
        },
        {
          label: 'Итого до',
          field: 'amount'
        },
        {
          label: 'Итого после',
          field: 'amountafter'
        }
      ]
    }
  },
  watch: {
    full: function (newValue) {
      if (newValue) this.amount = 0
      this.result.dl = null // это и в оферте full потом убрать
    }
  },
  methods: {
    buyoutModalDisactivation () { this.$store.state.buyoutModalToggle = false },
    async tryBuyout () {
      if (!this.amount && !this.full) {
        this.$notify({
          group: 'lsg-notify',
          duration: 10000,
          text: 'Необходимо указать размер платежа.'
        })
        return
      }
      this.loading = true
      const result = await this.$apollo.mutate({
        mutation: gql`
        mutation ($id: String!, $amount: Float!, $date: Date, $save: Boolean) {
          createBuyout (id: $id, amount: $amount, date: $date, save: $save)
        }
      `,
        variables: {
          amount: parseFloat(this.amount),
          id: this.contractId,
          date: this.date,
          save: this.save
        }
      })
        .catch(error => {
          _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
      this.loading = false
      if (result.data && result.data.createBuyout) {
        this.result = result.data.createBuyout
        if (this.save) {
          this.$notify({
            group: 'lsg-notify',
            duration: 10000,
            text: `Результат сохранен в системе 1С под номером ${result.data.createBuyout.doc_number}`
          })
          window.open(`${process.env.VUE_APP_HTTP}/download?type=contract&name=${this.contractName.split('-')[0]}&maket=e71433f8-8893-11eb-80c5-00259047d1e4&id=${this.contractId}_blank`)
        }
      } else {
        this.choosenSigners = (({ dl, act, dkp }) => ({ dl, act, dkp }))(this.signers)
        this.$notify({
          group: 'lsg-notify',
          duration: 10000,
          text: 'Не удалось расчитать график, обратитесь в поддержку.'
        })
      }
    },
    prettifyPrice (price) {
      return Number(price).toLocaleString()
    }
  },
  computed: {
    amountModel: {
      get () {
        return this.indicatorAmount ? this.amount : this.amount.toLocaleString()
      },
      set (value) {
        this.amount = +value.replace(/\s/g, '')
      }
    }
  }
}
</script>

<style scoped>
  .modal {
    z-index: 99999;
  }
  .modal__attention__btn {
    margin: 0px 5px;
  }
  button:disabled,
  button[disabled]{
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    pointer-events: none;
  }
  .radio-dp {
    align-items: center !important;
  }
  .radio-dp > label {
    height: 40px
  }
  .radio-dp > label > input[type="radio"] {
    height: 20px;
    width: 20px;
    margin-right: 10px
  }
  .radio-dp > label > span {
    display: inline-block
  }
</style>

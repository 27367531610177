<template>
  <div style="width: 100%">
    <div class="modal" style="align-items: flex-start" v-if="showModal">
      <div class="modal__back" @click="showModal = false"></div>
      <div class="modal__body modal__body--task-do">
        <div class="modal__attention" style="white-space: pre-wrap; overflow-y: auto">
          <span class="modal__attention__title">{{ selectedRow.name || selectedRow.fullname }}</span>
          <span v-if="selectedRow.tasks_comment" class="modal__attention__title" style="color:darkgrey;">{{selectedRow.tasks_comment}}</span>
          <!-- <div v-if="selectedRow.text !== '' || selectedRow.history !== ''" @click="() => subtitleExpanded = !subtitleExpanded"><a style="cursor: pointer">{{ subtitleExpanded? 'Свернуть': 'Развернуть'}}</a></div> -->
          <div class="modal__attention__container" style="overflow: auto">
            <!-- <span class="modal__attention__subtitle" style="color:darkgrey;width:70%;word-wrap:anywhere;">{{selectedRow.text}}</span> -->
            <div class="modal__attention__table">
              <vue-good-table
                :columns="columnsAgreement"
                :rows="history"
                compactMode
              >
                <template slot="table-row" slot-scope="props">
                  <div v-if="props.column.field === 'autor'">{{ props.row.autor }}</div>
                  <div v-if="props.column.field === 'result' && props.row.result === 'Согласовано'" class="tag tag--green">{{ props.row.result }}</div>
                  <div v-else-if="props.column.field === 'result' && props.row.result === 'СогласованоСЗамечаниями'" class="tag tag--yellow">{{ props.row.result }}</div>
                  <div v-else-if="props.column.field === 'result' && props.row.result === 'НеСогласовано'" class="tag tag--red">{{ props.row.result }}</div>
                  <div v-if="props.column.field === 'comment'">{{ props.row.comment }}</div>
                </template>
              </vue-good-table>
            </div>
            <div v-if="selectedRow.history" @click="openHistory = !openHistory" class="trade__modal__header" style="cursor: pointer">
              <span class="modal__attention__title">История</span>
              <div class="history__icon">
                <svg v-if="!openHistory" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5V19" stroke="#7a7a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M5 12H19" stroke="#7a7a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                <MinusIco v-else/>
              </div>
            </div>
            <span v-if="selectedRow.history" class="modal__attention__subtitle modal__attention__subtitle__history" :class="{'modal__attention__subtitle__history--active': openHistory}" style="color:darkgrey;">{{selectedRow.history}}</span>
          </div>
          <div v-if="docs.length > 0" style="width: 100%">
            <span class="modal__attention__title">Документы</span>
            <ul>
              <li v-for="(item, index) in docs" :key="index"><a class="modal__attention__files" :href="`${BASE_URL}/file?id=${item.id}`" download>{{`${item.name}.${item.ext}`}}</a></li>
            </ul>
          </div>
          <div v-if="selectedRow.user === $store.state.me.name && (selectedRow.path_dot==='Согласовать' || selectedRow.path_dot==='Исполнить' || 'Проверить исполнение')" style="margin:5px;width:70%;">
            <label style="color:grey;">Комментарий:</label>
            <input type="text" v-model="message" style="margin:3px;" >
          </div>
          <div class="modal__attention__btns" style="justify-content: center;">
            <button v-if="selectedRow.path_dot==='Исполнить' && selectedRow.user === $store.state.me.name && !selectedRow.result.length" class="modal__attention__btn modal__attention__btn--orange" @click="makeDone">Исполнено</button>
            <button v-if="selectedRow.path_dot==='Согласовать' && selectedRow.user === $store.state.me.name && !selectedRow.result.length" :disabled='noComment' class="modal__attention__btn modal__attention__btn--green" @click="makeApprove">Согласовано</button>
            <button v-if="selectedRow.path_dot==='Согласовать' && selectedRow.user === $store.state.me.name && !selectedRow.result.length" :disabled='noComment' class="modal__attention__btn modal__attention__btn--orange" @click="makeReject">Не согласовано</button>
            <button v-if="selectedRow.path_dot==='Проверить исполнение' && selectedRow.user === $store.state.me.name && !selectedRow.result.length" :disabled='noComment' class="modal__attention__btn modal__attention__btn--green" @click="makeDone">Проверено</button>
            <button v-if="selectedRow.path_dot==='Проверить исполнение' && selectedRow.user === $store.state.me.name && !selectedRow.result.length" :disabled='noComment' class="modal__attention__btn modal__attention__btn--orange" @click="makeReturnForRevision">Вернуть на доработку</button>
            <button v-if="selectedRow.path_dot.includes('Ознакомиться') && selectedRow.user === $store.state.me.name && !selectedRow.result.length" class="modal__attention__btn modal__attention__btn--green" @click="makeDone">Завершить</button>
            <button v-if="selectedRow.path_dot.includes('результат') && selectedRow.user === $store.state.me.name && selectedRow.result !== 'Согласовано'" class="modal__attention__btn modal__attention__btn--orange" @click="makeReturn">Повторить согласование</button>
            <button class="modal__attention__btn" @click="showModal = false">Закрыть</button>
          </div>
        </div>
      </div>
    </div>
    <div class="crm__notes comment_block">
      <div v-if="isLoading && $store.state.me.settings.lazy_load" class="messages apollo">
        <div class="messages__status">Загрузка...</div>
      </div>
      <div class="crm__notes__list crm__notes__list--tasks">
        <vue-good-table
          ref="doTasksTable"
          styleClass="vgt-table"
          :line-numbers="true"
          :columns="columns"
          @on-row-click="onRowClick"
          :rows="tasks"
          :group-options="{
            enabled: true,
            headerPosition: 'top',
            collapsable: true
          }"
          compactMode
          style="width: 100%; height: 100%; overflow-y: auto"
        >
        <!--  @on-selected-rows-change="selectedRowsChange" -->
          <template slot="table-header-row" slot-scope="props">
            <span style="color: black; cursor: pointer">
              <div style="display: inline-block; width: 230px; padding: 0 15px">
                <span v-if="props.row.label.result === 'Согласовано'" class="tag tag--green" style="width: 100%; display: inline-block">{{ props.row.label.result }}</span>
                <span v-else-if="props.row.label.result === 'СогласованоСЗамечаниями'" class="tag tag--yellow" style="width: 100%; display: inline-block">{{ props.row.label.result }}</span>
                <span v-else-if="props.row.label.result === 'НеСогласовано'" class="tag tag--red" style="width: 100%; display: inline-block">{{ props.row.label.result }}</span>
                <span v-else-if="props.row.label.bp_date !== '0001-01-01T00:00:00'" class="tag" style="width: 100%; display: inline-block">Завершено</span>
              </div>
              <span>{{ props.row.label.samplebp }}</span>
            </span>
          </template>
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field === 'path_dot'">{{ props.row.path_dot }}</div>
            <div v-if="props.column.field === 'user'">{{ props.row.user }}</div>
            <div v-if="props.column.field === 'result' && props.row.path_dot === 'Ознакомиться с результатом согласования'"></div>
            <div v-else-if="props.column.field === 'result' && props.row.result === 'Согласовано'" class="tag tag--green">{{ props.row.result }}</div>
            <div v-else-if="props.column.field === 'result' && props.row.result === 'СогласованоСЗамечаниями'" class="tag tag--yellow">{{ props.row.result }}</div>
            <div v-else-if="props.column.field === 'result' && props.row.result === 'НеСогласовано'" class="tag tag--red">{{ props.row.result }}</div>
            <div v-if="props.column.field === 'date'">{{ new Date(props.row.date).toLocaleString() }}</div>
            <div v-if="props.column.field === 'due_date' && props.row.path_dot !== 'Ознакомиться с результатом согласования'">{{ new Date(props.row.due_date).toLocaleString() }}</div>
            <div v-if="props.column.field === 'tasks_comment'">{{ props.row.tasks_comment }}</div>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
// import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import TASKS_DO from '@/graphql/queries/LeadTasksDo.gql'
import VISAS from '@/graphql/queries/DocVisas.gql'
import axios from 'axios'
import gql from 'graphql-tag'
import MinusIco from '@/components/svg/minus'

// import ConfirmIcon from '@/components/svg/ConfirmIcon'

const _graphQlHelper = new GraphQLHelper()
const BASE_URL = process.env.VUE_APP_HTTP

export default {
  name: 'LsgTasks',
  components: {
    // PerfectScrollbar,
    MinusIco
    // ConfirmIcon
  },
  props: ['id'],
  data () {
    return {
      BASE_URL,
      tasks: [],
      message: '',
      isLoading: true,
      selectedRow: {},
      showModal: false,
      docs: [],
      openHistory: false,
      history: [],
      columnsAgreement: [
        {
          label: 'Согласующее лицо',
          field: 'autor'
        },
        {
          label: 'Результат',
          field: 'result'
        },
        {
          label: 'Комментарий',
          field: 'comment'
        }
      ],
      columns: [
        {
          field: 'path_dot',
          label: 'Задача'
        },
        {
          field: 'user',
          label: 'Исполнитель'
        },
        {
          field: 'due_date',
          label: 'Срок исполнения'
        },
        {
          field: 'result',
          label: 'Статус'
        },
        {
          field: 'tasks_comment',
          label: 'Комментарий',
          width: '250px'
        }
      ]
    }
  },
  beforeMount () {
    this.getTasks()
    // this.getVisas()
  },
  methods: {
    async onRowClick (params) {
      this.selectedRow = params.row
      this.docs = await this.getDocs(params.row.doc)
      this.getVisas(params.row.doc)
      this.showModal = true
    },
    getVisas (doc) {
      this.$apollo
        .mutate({
          mutation: VISAS,
          variables: {
            doc: doc
          },
          update: (_, { data }) => {
            if (data && data.docVisas.length > 0) {
              this.history = [...data.docVisas]
            }
          }
        })
        .catch((error) => {
          _graphQlHelper.graphQLErrorMessages(error).forEach((item) => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
    },
    getTasks () {
      this.$apollo
        .mutate({
          mutation: TASKS_DO,
          variables: {
            id: this.id
          },
          update: (_, { data }) => {
            if (data && data.allLeadDoTasks.length > 0) {
              this.history = data.allLeadDoTasks[0].history
              this.tasks = data.allLeadDoTasks.map(task => {
                return {
                  mode: 'span',
                  label: { samplebp: task.samplebp, bp_date: task.bp_date, result: task.result },
                  children: task.tasks,
                  html: false
                }
              })
            }
          }
        })
        .catch((error) => {
          _graphQlHelper.graphQLErrorMessages(error).forEach((item) => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
    },
    onScroll (event) {
      this.isLoading = this.onLoading
      if (this.$refs.list.$el.scrollTop === 0) {
        this.$emit('update:position1', this.$refs.list.ps.contentHeight)
      }
    },
    async openTask (task) {
      this.selectedRow = task
      this.showModal = true
      this.docs = await this.getDocs(task.doc)
    },
    async getDocs (id) {
      const docs = await axios.get(`${BASE_URL}/doc?uid=${id}`)
      return docs.data
    },
    async downloadFile (id) {
      await axios.get(`${BASE_URL}/file?id=${id}`)
    },
    makeDone () {
      this.showModal = false
      this.updateTask('done') // Исполнено или ознакомлено
    },
    makeApprove () {
      this.showModal = false
      this.updateTask('approve') // Согласовано
    },
    makeReject () {
      this.showModal = false
      this.updateTask('reject') // Не согласовано
    },
    makeReturn () {
      this.showModal = false
      this.updateTask('return') // Повторить согласование
    },
    makeReturnForRevision () {
      this.showModal = false
      this.updateTask('revision') // Доработка
    },
    async updateTask (type) {
      await this.$apollo.mutate({
        mutation: gql`mutation updateDOTask($ids: [String!], $type: String, $comment:String) {
          updateDOTask (ids: $ids, type: $type, comment: $comment)
        }`,
        variables: {
          type,
          comment: this.message,
          ids: [this.selectedRow.id]
        }
      }).then(() => {
        this.$router.go()
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    }
  }
}
</script>

<style lang="stylus">

.lsg-trade
  .main__content
    padding-bottom 30px !important

.crm
  align-items stretch !important
  max-height calc(100vh - 225px)

  +below(768px)
    display block !important
    max-height none
    width auto !important
    margin 0 !important

.crm__aside
  height auto !important

  +below(980px)
    max-width 350px !important

  +below(768px)
    max-width none !important
    width auto
    height auto !important
    margin 0 0 30px !important

.comment_block
  flex 1
  align-items initial
  justify-content flex-start
  max-width initial
  margin 0 -32px 0 -16px !important
  padding 0 !important
  max-height 100%
  max-width initial !important

  &::before
    content ''
    absolute left 16px top
    width calc(100% - 32px)
    height 30px
    background linear-gradient(top, $gray, alpha($gray, 0))
    z-index 5

  +below(768px)
    margin 0 15px 0 0 !important

.comment_block__top
  flex 1
  padding 15px
  overflow auto
  max-height 100%
  box-sizing border-box

  +below(768px)
    max-height 500px

  ul
    height auto
    padding 0
    margin 0
    overflow initial

.comment_block__bottom
  padding 15px 16px 0
  position relative

  .crm__notes__overdue
    position initial
    width auto
    margin 0 20px
    transform translateY(0)

  .crm__notes__form
    margin 0

  .modal {
    align-items: center;
  }
  .modal__body--task-do {
    max-width: 50%;
  }
  @media (max-width: 768px) {
    .modal__body--task-do {
      max-width: 90%;
    }
  }
  .modal__attention__table {
    margin-bottom: 15px;
  }
  .modal__attention__subtitle__history {
    display: none !important
  }
  .modal__attention__subtitle__history--active {
    display: inline-block !important
  }
  .modal__attention__files {
    cursor: pointer;
    color: #FF8000
  }
  .modal__attention__files:hover {
    text-shadow: 1px 1px 1px #FF8000
  }
  .modal__attention__container {
    overflow-y: scroll;
    white-space: pre-wrap;
    max-height: 600px;
    width: 100%
  }
  .modal__attention__btns {
    display flex
    flex-direction row
    justify-content space-between
  }
  .modal__attention__btn {
    background-color: antiquewhite;
    margin: 0px 5px;
  }
  .open {
    height: auto
  }
  button:disabled,
  button[disabled]{
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    pointer-events: none;
  }

.crm__note__icon--red svg path[fill] {
  fill: #ff0000 !important
}
.crm__note__icon--black svg path[fill] {
  fill: #000000 !important
}
.crm__note__icon--green svg path[fill] {
  fill: $anothergreen !important
}
.crm__note__icon--orange svg path[fill] {
  fill: #ff8000 !important
}

.crm__notes__list--tasks {
  height: 100vh !important
  padding: 0 16px !important
}
</style>

<template>
  <div class="modal" v-if="filesModal">
    <div class="modal__back" @click="$emit('switchModal')"></div>
    <div class="modal__body trade__modal--clamp">
      <form class="trade__modal" @submit.prevent="generateKPF" autocomplete="off">
        <div v-if="!isDownload">
          <div @click="openSignatories = !openSignatories" class="trade__modal__header" style="cursor: pointer">
            <h3>Подписанты</h3>
            <div class="signatories__icon">
              <svg v-if="!openSignatories" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 5V19" stroke="#7a7a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M5 12H19" stroke="#7a7a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
              <MinusIco v-else/>
            </div>
          </div>
          <!-- {{maket}} -->
          <div class="trade__modal__body signatories" :class="{'signatories--active': openSignatories}" style="margin-bottom:0px;">
            <div class="ct__input">
              <label>Подписант ДЛ:</label>
              <v-select
                label="name"
                v-model="choosenSigners.dl"
                :options="signers.dllist"
                :reduce="item => item.id"
                placeholder="Выберите подписанта ДЛ"
              />
            </div>
            <div class="ct__input">
              <label>Подписант АЛ и АКП:</label>
              <v-select
                label="name"
                v-model="choosenSigners.act"
                :options="signers.actlist"
                :reduce="item => item.id"
                placeholder="Выберите подписанта АЛ и АКП"
              />
            </div>
            <div class="ct__input">
              <label>Подписант поставщик:</label>
              <v-select
                label="name"
                v-model="choosenSigners.dkp"
                :options="signers.dkplist"
                :reduce="item => item.id"
                placeholder="Выберите подписанта поставщика"
              />
            </div>
          </div>
          <div class="signatories__border"></div>
          <div class="trade__modal__header">
            <h3>Сформировать документы</h3>
          </div>
          <div class="trade__modal__body">
            <vue-good-table
              ref="contractFilesTable"
              styleClass="vgt-table"
              :line-numbers="true"
              :columns="columns"
              :rows="dataProcessing(apolloData.maketList)"
              :select-options="{
                enabled: true,
                selectAllByGroup: true,
                disableSelectInfo: true
              }"
              @on-selected-rows-change="selectedRowsChange"
              :group-options="{
                enabled: true,
                headerPosition: 'top',
                collapsable: true
              }"
              style="width: 100%; overflow-x: none"
            >
              <template slot="table-header-row" slot-scope="props">
                <span style="color: black; cursor: pointer;">
                  {{ props.row.label }}
                </span>
              </template>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'name'">{{ props.row.name }}</span>
              </template>
            </vue-good-table>
          </div>
          <div class="trade__modal__footer">
            <button type="submit" class="btn">Скачать</button>
            <button class="modal__attention__btn" @click="$emit('switchModal')">Закрыть</button>
          </div>
        </div>
        <div v-else>
          <div class="trade__modal__header">
            <h3>Загрузить документы</h3>
          </div>
          <div class="trade__modal__body trade__modal__body--out">
            <ul class="ct__doc__list">
              <li v-for="(nfile, index) in newFiles" :key="index + 100" class="ct__doc__list__item">
                <a class="ct__doc__link" href="javascript:void(0)">{{ nfile.file.name }}</a>
                <v-select appendToBody :options="apolloData.documentTypes.map(file => ({
                    label: file.type,
                    code: {
                      doId: contractId,
                      doType: 'DMInternalDocument',
                      owType: 'Договор',
                      ...file
                    }
                  }))" v-model="nfile.info" style="max-width: 50%">
                  <template slot="no-options">Нет результатов</template>
                  <template #open-indicator="{ attributes }">
                    <div v-bind="attributes">
                      <DownIcon/>
                    </div>
                  </template>
                </v-select>
              </li>
            </ul>
            <div class="ct__doc__drop">
              <vue-dropzone
                id="dropzone"
                @vdropzone-files-added="filesPicked($event)"
                :includeStyling="false"
                useCustomSlot
                :options="dropzoneOptions">
                <div class="lk__dz" :class="{'lk__dz--active': dropPicked}">
                  <i>
                    <PaperClip/>
                  </i>
                  <span>Выберите или перетащите сюда файлы</span>
                </div>
              </vue-dropzone>
            </div>
          </div>
          <div class="trade__modal__footer">
            <button class="btn" preventDefault @click="uploadFiles" :disabled="!validUpload || blockButton">Загрузить</button>
            <button class="modal__attention__btn" @click="$emit('switchModal')">Закрыть</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import PaperClip from '@/components/svg/PaperClip'
import DownIcon from '@/components/svg/DownIcon'
import axios from 'axios'
import gql from 'graphql-tag'
import MinusIco from '@/components/svg/minus'

export default {
  name: 'CreateContractModal',
  props: [ 'filesModal', 'mongoId', 'contractId', 'apolloData', 'contractName', 'signers', 'isDownload' ],
  components: {
    MinusIco,
    PaperClip,
    DownIcon,
    vueDropzone: vue2Dropzone
  },
  data () {
    return {
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        acceptedFiles: 'application/pdf'
      },
      dropPicked: false,
      openSignatories: false,
      newFiles: [],
      checkMaket: false,
      checkMultiMaket: false,
      maketData: [],
      choosenSigners: {},
      blockButton: false,
      columns: [
        {
          field: 'name',
          label: 'Документ'
        }
      ],
      sort: {
        'Договор лизинга': ['График лизинга ОБЩИЙ', 'ДЛ (Менеджер)', 'Заявление на лизинг ОБЩЕЕ', 'Спецификация по ДЛ ОБЩАЯ'],
        'Договор купли-продажи': ['ДКП ОБЩИЙ', 'Спецификация по ДКП ОБЩАЯ', 'Условия Гарантии ОБЩИЕ', 'Условия ПНР'],
        'Доверенности': ['Довер. на Лизингоп-ля (МСБ)', 'Довер. на руководителя (Менеджер)', 'Довер. от Лизингодателя на Лизингополучателя (юридическое лицо) на получение имущества от Продавца', 'Довер. от Лизингодателя на Лизингополучателя (юридическое лицо) на получение имущества от Продавца. КАЗАНЬ.', 'Довер. от Лизингополучателя на выкуп имущества ( по окончанию лизинга)', 'Довер. от Лизингополучателя на получения имущества "водителем Лизингополучателя"', 'Довер. от Лизингополучателя на получения имущества от Продавца. ПЕРЕДОВЕРИЕ'],
        'Дополнительные соглашения': ['Доп соглашение к ДЛ', 'Доп соглашение к ДЛ _ООО Авто-партнер', 'Доп соглашение к ДЛ на смену наименования ЛП', 'ДС к ДП', 'ДС_к_ДКП'],
        'Акты': ['Акт ДКП ОБЩИЙ', 'Акт ДЛ ОБЩИЙ', 'Акт передачи ключей ОБЩИЙ'],
        'Другие документы': []
      }
    }
  },
  created () {
    this.choosenSigners = (({ dl, act, dkp }) => ({ dl, act, dkp }))(this.signers)
  },
  watch: {
    maketData: function (newValue) {
      this.checkMultiMaket = false
      this.checkMaket = false
      if (newValue.length > 0) this.checkMaket = true
      if (newValue.length > 1) this.checkMultiMaket = true
    }
  },
  computed: {
    validUpload () {
      let valid = true
      this.newFiles.forEach(file => {
        if (!file.info) {
          valid = false
        }
      })
      return valid && this.newFiles.length
    },
    signersSame () {
      return this.signers.dl === this.choosenSigners.dl &&
        this.signers.act === this.choosenSigners.act &&
        this.signers.dkp === this.choosenSigners.dkp
    }
  },
  methods: {
    selectedRowsChange ({ selectedRows }) {
      const maket = selectedRows.map(row => ({ id: row.id, name: row.name, checked: true }))
      this.maketData = maket
    },
    // findKey (obj, value) {
    //   for (let key in obj) {
    //     if (obj[key].includes(value)) return key
    //   }
    //   return null
    // },
    dataProcessing (input) {
      const sortFields = Object.keys(this.sort)
      const data = sortFields.map(label => ({
        mode: 'div',
        label: label,
        html: false,
        children: []
      }))

      // input.forEach(elem => {
      //   const key = this.findKey(this.sort, elem.name)
      //   data[sortFields[key]].push({ name: elem.name, id: elem.id, checked: false })
      // })

      input.forEach(elem => { // TODO: Рефакторинг
        if (this.sort['Договор лизинга'].includes(elem.name)) {
          data[0].children.push({ name: elem.name, id: elem.id, checked: false })
        } else if (this.sort['Договор купли-продажи'].includes(elem.name)) {
          data[1].children.push({ name: elem.name, id: elem.id, checked: false })
        } else if (this.sort['Доверенности'].includes(elem.name)) {
          data[2].children.push({ name: elem.name, id: elem.id, checked: false })
        } else if (this.sort['Дополнительные соглашения'].includes(elem.name)) {
          data[3].children.push({ name: elem.name, id: elem.id, checked: false })
        } else if (this.sort['Акты'].includes(elem.name)) {
          data[4].children.push({ name: elem.name, id: elem.id, checked: false })
        } else {
          data[5].children.push({ name: elem.name, id: elem.id, checked: false })
        }
      })
      return data
    },
    async generateKPF () {
      const maketIds = this.maketData.map(el => el.id).join(';')
      if (!this.checkMaket) {
        this.$notify({
          group: 'lsg-notify',
          duration: 10000,
          text: 'Необходимо выбрать макеты.'
        })
        return
      }
      if (this.signersSame) {
        window.open(`${process.env.VUE_APP_HTTP}/download?type=contract&name=${this.contractName.split('-')[0]}&maket=${maketIds}&id=${this.contractId}_blank`)
      } else {
        const result = await this.$apollo.mutate({
          mutation: gql`
          mutation ($signers: JSON!, $contract_id: String!) {
            updateSigners (signers: $signers, contract_id: $contract_id)
          }
        `,
          variables: {
            signers: this.choosenSigners,
            contract_id: this.contractId
          }
        })

        if (result.data && result.data.updateSigners) {
          this.signers.dl = this.choosenSigners.dl
          this.signers.dkp = this.choosenSigners.dkp
          this.signers.act = this.choosenSigners.act
          window.open(`${process.env.VUE_APP_HTTP}/download?type=contract&name=${this.contractName.split('-')[0]}&maket=${maketIds}&id=${this.contractId}_blank`)
        } else {
          this.choosenSigners = (({ dl, act, dkp }) => ({ dl, act, dkp }))(this.signers)
          this.$notify({
            group: 'lsg-notify',
            duration: 10000,
            text: 'Не удалось обновить подписантов, обратитесь в поддержку.'
          })
        }
      }
    },
    async uploadFiles () {
      this.blockButton = true
      this.$store.dispatch({ type: 'showOverlay', show: true, text: 'Подождите, идет обработка...' })
      let formData = new FormData()
      let index = 0
      this.newFiles.forEach((item) => {
        formData.append(`file[${index}][file]`, item.file)
        Object.keys(item.info.code).forEach(key => {
          formData.append(`file[${index}][${key}]`, item.info.code[key])
        })
        formData.append(`file[${index}][user]`, this.$store.state.me.name)
        index += 1
      })
      const BASE_URL = `${process.env.VUE_APP_HTTP}`
      const url = `${BASE_URL}/upload`
      axios.post(url, formData)
        .then(res => {
          if (res.status === 200) {
            this.blockButton = false
            this.$store.dispatch({ type: 'hideOverlay' })
            this.$router.push({ name: 'contract', params: { id: this.mongoId } })
            this.$emit('switchModal')
            this.$notify({
              group: 'lsg-notify',
              text: 'Файлы успешно загружены'
            })
          }
        })
        .catch(() => {
          this.blockButton = false
          this.$store.dispatch({ type: 'hideOverlay' })
          this.$notify({
            group: 'lsg-notify',
            duration: 10000,
            text: 'Ошибка при загрузке, обратитесь в поддержку.'
          })
        })
    },
    filesPicked (e) {
      this.dropPicked = true
      this.newFiles = Array.from(e).map(file => ({ file: file, info: null }))
    }
  }
}
</script>

<style scoped>
  .dz-clickable {
    min-height: 68px;
  }
  .trade__modal__body--out {
    margin-bottom: 0
  }
  .lk__dz {
    max-width: 100vw;
    min-width: 100%;
  }
  .lk__dz--active {
    height: 68px;
  }
  .modal {
    z-index: 99999;
  }
  .modal__attention__btn {
    margin: 0px 5px;
  }
  button:disabled,
  button[disabled]{
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    pointer-events: none;
  }
  .signatories {
    display: none;
  }
  .vgt-responsive {
    overflow-x: hidden !important
  }
  .signatories--active {
    display: block
  }
  .signatories__border {
    border-bottom: 3px solid #e8e8f6;
    margin-bottom: 10px;
  }
  .active {
    transform: rotate(180deg);
  }
  .ct__input {
    flex-wrap: wrap
  }
  .ct__doc__link {
    width: 50%
  }
  h3 {
    margin-bottom: 0;
  }
</style>

<style lang="stylus">
.vs__dropdown-menu {
  z-index: 99999
}
</style>
